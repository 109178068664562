import { CommonModule, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { SelettoreLinguaComponent } from './selettore-lingua/selettore-lingua.component';
import { Observable, tap } from 'rxjs';

import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';


@NgModule({
  declarations: [
    SelettoreLinguaComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
  ],
  exports: [
    SelettoreLinguaComponent
  ]
})
export class LinguaModule { }