import { ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/core/services/language.service';
import { UserService } from 'src/app/core/services/user.service';
import { take } from 'rxjs/operators';
import { LanguageCulture } from 'src/app/core/autogenerated/model.autogenerated';

@Component({
  selector: 'selettore-lingua',
  templateUrl: './selettore-lingua.component.html',
  styleUrls: ['./selettore-lingua.component.css'],
})
export class SelettoreLinguaComponent implements OnInit {

  public languages: LanguageCulture[] = [];
  public currentLanguage: LanguageCulture | null = null;
  public isUserLogged: boolean = false;

  constructor(
    private readonly _language: LanguageService,
    private changeDetector: ChangeDetectorRef,
    private _datiUtenteService: UserService
  ) {

  }

  ngOnInit(): void {
    this.languages = this._language.supportedLanguages;

    this._datiUtenteService.datiUtente$
      .subscribe(utente => {
        this.isUserLogged = utente != null;
      });

    // Sottoscrivo questo servizio agli eventi del servizio di gestione delle lingue
    this._language.currentLanguage$
      .subscribe(language => {
        if (language != null) {
          this.currentLanguage = this.languages.find(x => x.languageCultureName == language.languageCultureName)!;
        }
      });
  }

  // Cambia la lingua. Formato: it, en, es...
  public changeLanguage() {
    if (this.currentLanguage) {
      this._language.setUserLanguage(this.currentLanguage)
        .pipe(
          take(1)
        ).subscribe();
    }
  }
}
